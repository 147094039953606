
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import HeaderTit from '@/components/HeaderTit.vue'
import { getRecordId } from '../commons/Utils'
export default defineComponent({
  name: 'Notice',
  components: {
    HeaderTit
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const institutionId = ref()
    onMounted(async () => {
      if (route.query.institutionId) {
        institutionId.value = route.query.institutionId
      }
      getRecordId(route.query.id, '进入告知事项页面', true)
    })

    const submitFun = () => {
     
        router.push({
          path:'/confirmation',
          query: {
            id: route.query.id,
            caseNumberWord: route.query.caseNumberWord,
            receiverName: route.query.receiverName,
            institutionId: route.query.institutionId
          }
        })
    }
    return {
      submitFun,
      institutionId
    }
  }
})
